const systemColors = {
	white: 'rgb(255, 255, 255)',
	black: 'rgb(0, 0, 0)',
	redLight: 'rgb(233, 78, 54)',
	orangeLight: 'rgb(239, 153, 45)',
	yellowLight: 'rgb(246, 204, 61)',
	greenLight: 'rgb(104, 195, 101)',
	mintLight: 'rgb(94, 196, 190)',
	tealLight: 'rgb(92, 174, 197)',
	cyanLight: 'rgb(92, 172, 226)',
	blueLight: 'rgb(54, 124, 248)',
	indigoLight: 'rgb(87, 91, 208)',
	purpleLight: 'rgb(162, 93, 216)',
	pinkLight: 'rgb(232, 70, 86)',
	brownLight: 'rgb(157, 133, 98)',
	gray1Light: 'rgb(142, 142, 147)',
	gray2Light: 'rgb(174, 174, 178)',
	gray3Light: 'rgb(199, 199, 204)',
	gray4Light: 'rgb(209, 209, 214)',
	gray5Light: 'rgb(229, 229, 234)',
	gray6Light: 'rgb(242, 242, 247)',
	redDark: 'rgb(233, 85, 63)',
	orangeDark: 'rgb(240, 162, 50)',
	yellowDark: 'rgb(248, 214, 65)',
	greenDark: 'rgb(108, 204, 102)',
	mintDark: 'rgb(138, 227, 226)',
	tealDark: 'rgb(110, 201, 222)',
	cyanDark: 'rgb(131, 208, 252)',
	blueDark: 'rgb(61, 134, 248)',
	indigoDark: 'rgb(93, 97, 223)',
	purpleDark: 'rgb(177, 101, 235)',
	pinkDark: 'rgb(233, 76, 95)',
	brownDark: 'rgb(167, 143, 108)',
	gray1Dark: 'rgb(142, 142, 147)',
	gray2Dark: 'rgb(99, 99, 102)',
	gray3Dark: 'rgb(72, 72, 74)',
	gray4Dark: 'rgb(58, 58, 60)',
	gray5Dark: 'rgb(44, 44, 46)',
	gray6Dark: 'rgb(28, 28, 30)',
} as const;

export const colors = {
	system: systemColors,
	backgrounds: {
		light: {
			primary: systemColors.white,
			secondary: systemColors.gray6Light,
			tertiary: systemColors.white,
		},
		darkElevated: {
			primary: systemColors.gray6Dark,
			secondary: systemColors.gray5Dark,
			tertiary: systemColors.gray4Dark,
		},
		dark: {
			primary: systemColors.black,
			secondary: systemColors.gray6Dark,
			tertiary: systemColors.gray5Dark,
		},
	},
	groupedBackgrounds: {
		light: {
			primary: systemColors.gray6Light,
			secondary: systemColors.white,
			tertiary: systemColors.gray6Light,
		},
		darkElevated: {
			primary: systemColors.gray6Dark,
			secondary: systemColors.gray5Dark,
			tertiary: systemColors.gray4Dark,
		},
		dark: {
			primary: systemColors.black,
			secondary: systemColors.gray6Dark,
			tertiary: systemColors.gray5Dark,
		},
	},
	labels: {
		light: {
			primary: systemColors.black,
			secondary: 'rgba(60, 60, 67, 0.60)',
			tertiary: 'rgba(60, 60, 67, 0.30)',
			quaternary: 'rgba(60, 60, 67, 0.18)',
		},
		dark: {
			primary: systemColors.white,
			secondary: 'rgba(235, 235, 245, 0.60)',
			tertiary: 'rgba(235, 235, 245, 0.30)',
			quaternary: 'rgba(235, 235, 245, 0.16)',
		},
	},
	fills: {
		light: {
			primary: 'rgba(120, 120, 128, 0.20)',
			secondary: 'rgba(120, 120, 128, 0.16)',
			tertiary: 'rgba(120, 120, 128, 0.12)',
			quaternary: 'rgba(120, 120, 128, 0.08)',
		},
		dark: {
			primary: 'rgba(120, 120, 128, 0.36)',
			secondary: 'rgba(120, 120, 128, 0.32)',
			tertiary: 'rgba(120, 120, 128, 0.24)',
			quaternary: 'rgba(120, 120, 128, 0.18)',
		},
	},
	separators: {
		light: {
			opaque: 'rgba(198, 198, 200, 1)',
			translucent: 'rgba(84, 84, 86, 0.34)',
		},
		dark: {
			opaque: 'rgba(56, 56, 58, 1)',
			translucent: 'rgba(84, 84, 86, 0.6)',
		},
	},
} as const;

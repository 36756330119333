import { queryOptions } from '@tanstack/react-query';

import { getActiveAnnouncementsForUser, getAllAnnouncementsForUser } from './api';

export const getActiveAnnouncementsQueryOptions = (enabled: boolean = true) =>
	queryOptions({
		queryKey: ['active-announcements'],
		queryFn: ({ signal }) => getActiveAnnouncementsForUser(signal),
		enabled,
	});

export const getUserAnnouncementsQueryOptions = queryOptions({
	queryKey: ['user-announcements'],
	queryFn: ({ signal }) => getAllAnnouncementsForUser(signal),
});

import { SimpleGrid } from '@mantine/core';
import type { ToOptions } from '@tanstack/react-router';

import CustomIconButton from '@apple/ui/layouts/components/button-with-icon';
import type { IconElement } from '@apple/assets/icons';

export type DashboardCard = ToOptions & {
	title: string;
	icon: IconElement;
	dataTestId?: string;
	color: string;
};

type Props = {
	cards: DashboardCard[];
};

export function Dashboard({ cards }: Props) {
	return (
		<SimpleGrid cols={{ base: 1, md: 2 }} py='sm' px='md' mx='xl'>
			{cards.map(({ icon: IconElement, title, color, dataTestId, ...toOptions }, index) => (
				<CustomIconButton
					{...toOptions}
					icon={<IconElement size='1.5rem' height='1.5rem' width='1.5rem' />}
					text={title}
					key={index}
					backgroundColor={color}
					datatestId={dataTestId}
				/>
			))}
		</SimpleGrid>
	);
}

import { memo, useEffect } from 'react';
import { Input, TextInput } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { Link } from '@mantine/tiptap';
import { TextAlign } from '@tiptap/extension-text-align';
import { Underline } from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import type { Editor } from '@tiptap/react';

import { useTranslation } from '@apple/lib/i18next';
import { DEFAULT_CULTURE } from '@apple/utils/globalization';

import { TextEditor } from '../TextEditor';

export const TranslationDetail = memo(
	({
		culture,
		currentLocalization,
		onLocalizationChanged,
	}: {
		culture: string;
		currentLocalization: { culture: string; description: string; title: string };
		onLocalizationChanged: ({
			culture,
			description,
			title,
		}: {
			culture: string;
			description: string;
			title: string;
		}) => void;
	}) => {
		const { t } = useTranslation('product');

		const editor = useEditor({
			extensions: [
				StarterKit,
				Underline,
				Link,
				TextAlign.configure({ types: ['heading', 'paragraph'] }),
			],
			editable: currentLocalization.culture !== DEFAULT_CULTURE,
			content: currentLocalization.description,
		});

		const handleDescriptionUpdate = useDebouncedCallback((editor: Editor) => {
			const content = editor.getHTML();
			const text = editor.getText();
			const currentTitle = currentLocalization.title;

			onLocalizationChanged({
				culture: currentLocalization.culture,
				title: currentTitle,
				description: text ? content : '',
			});
		}, 500);

		useEffect(() => {
			if (editor) {
				editor.on('update', () => handleDescriptionUpdate(editor));
				//added this to refresh editor when clear is clicked.
				!currentLocalization.description && editor.commands.clearContent();
			}

			return () => {
				editor && editor.off('update', () => handleDescriptionUpdate(editor));
			};
		}, [currentLocalization, editor, handleDescriptionUpdate]);

		return (
			<>
				<TextInput
					label={t('details.labels.name')}
					value={currentLocalization.title}
					onChange={e =>
						onLocalizationChanged({
							culture: currentLocalization.culture,
							description: currentLocalization.description,
							title: e.currentTarget.value,
						})
					}
					readOnly={culture === DEFAULT_CULTURE}
					withAsterisk={!!currentLocalization.description}
					error={
						!currentLocalization.title &&
						currentLocalization.description &&
						t('zod:errors.LOC_ValidationMessage_Required')
					}
					data-testid='product-name-input'
				></TextInput>
				{editor && (
					<Input.Wrapper
						label={t('details.labels.description')}
						withAsterisk={!!currentLocalization.title}
						error={
							currentLocalization.title &&
							!currentLocalization.description &&
							t('zod:errors.LOC_ValidationMessage_Required')
						}
					>
						<TextEditor editor={editor} displayToolBar={culture !== DEFAULT_CULTURE} />
					</Input.Wrapper>
				)}
			</>
		);
	},
);

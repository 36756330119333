import React, { memo } from 'react';
import { Button, Combobox, Tooltip } from '@mantine/core';
import type { ButtonProps, ComboboxStore } from '@mantine/core';

import { icons } from '@apple/assets';

interface FilterBarDropdownProps extends ButtonProps {
	store: ComboboxStore;
	options: React.ReactNode;
	onOptionSubmit: (value: string) => void;
	targetIcon: React.ReactNode;
	toolTip?: string | undefined;
	testId?: string | undefined;
}

export const FilterBarDropdown = memo<FilterBarDropdownProps>(
	({
		store,
		onOptionSubmit,
		options,
		targetIcon,
		toolTip,
		testId,
		...others
	}: FilterBarDropdownProps) => {
		const dropdownButton = (
			<Button
				onClick={() => store.toggleDropdown()}
				rightSection={<icons.ChevronDown />}
				data-testid={testId}
				{...others}
			>
				{targetIcon}
			</Button>
		);

		return (
			<Combobox
				store={store}
				onOptionSubmit={onOptionSubmit}
				position={'bottom-start'}
				width={200}
			>
				<Combobox.Target>
					{toolTip ? <Tooltip label={toolTip}>{dropdownButton}</Tooltip> : dropdownButton}
				</Combobox.Target>

				<Combobox.Dropdown>
					<Combobox.Options>{options}</Combobox.Options>
				</Combobox.Dropdown>
			</Combobox>
		);
	},
);

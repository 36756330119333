import { ActionIcon, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';

import { DarkModeIcon, LightModeIcon } from '@apple/assets/icons';

export function ToggleColorSchemeButton() {
	const { setColorScheme } = useMantineColorScheme();
	const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

	return (
		<ActionIcon
			onClick={() => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')}
			variant='transparent'
			c={'light-dark(var(--mantine-color-black), var(--mantine-color-white))'}
			size='md'
			aria-label='Toggle color scheme'
		>
			{computedColorScheme === 'light' ? (
				<DarkModeIcon size='80%' strokeWidth={1.5} />
			) : (
				<LightModeIcon size='80%' strokeWidth={1.5} />
			)}
		</ActionIcon>
	);
}

import axios from 'axios';

import {
	commentSchema,
	orderLineItemSchema,
	purchaseOrderLineSchema,
	purchaseOrderResponseSchema,
} from '../models/details';
import type {
	Comment,
	ItemQuantity,
	Message,
	OrderLineItem,
	PurchaseOrderLine,
	PurchaseOrderResponse,
} from '../models/details';

export async function getPurchaseOrder(poNumber: string): Promise<PurchaseOrderResponse> {
	const data = (await axios.get<unknown>(`/api/orders/${poNumber}`)).data;
	return purchaseOrderResponseSchema.parse(data);
}

export async function getPurchaseOrderLines(poNumber: string): Promise<PurchaseOrderLine[]> {
	const data = (await axios.get<unknown>(`/api/orders/${poNumber}/line-items`)).data;
	return purchaseOrderLineSchema.array().parse(data);
}

export async function getComments(poNumber: string) {
	const data = (await axios.get<unknown>(`/api/orders/${poNumber}/comments`)).data;
	return commentSchema.array().parse(data);
}

export async function requestOrderCancellation(purchaseOrderNumber: string) {
	await axios.post(`/api/order-cancellation/${purchaseOrderNumber}/cancel`);
}

export async function saveComment(message: Message, poNumber: string): Promise<Comment> {
	const data = (await axios.post<unknown>(`/api/orders/${poNumber}/comments/add`, message)).data;
	return commentSchema.parse(data);
}

export async function addLine(lineItem: ItemQuantity, poNumber: string): Promise<OrderLineItem[]> {
	const data = (await axios.post<unknown>(`/api/orders/${poNumber}/add-line`, lineItem)).data;
	return orderLineItemSchema.array().parse(data);
}

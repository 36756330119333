import { Group, rem, ThemeIcon, Title } from '@mantine/core';
import type { MantineSize } from '@mantine/core';
import type { ToOptions } from '@tanstack/react-router';

import { AppleLogo as AppleLogoIcon } from '@apple/assets/icons';
import { Link } from '@apple/ui/link';

type Props = {
	iconSize?: MantineSize | (string & {}) | number;
	labelSize?: MantineSize | (string & {}) | number;
	label?: string;
	to?: ToOptions['to'];
};

export function AppleLogo({ iconSize = 'md', labelSize = 'md', label, ...toProps }: Props) {
	function Logo() {
		const words = label?.split(' ');
		const firstWord = words?.shift();
		if (label) {
			return (
				<Group
					wrap='nowrap'
					align='center'
					justify='flex-start'
					gap={rem(4)}
					c='light-dark(var(--mantine-color-black), var(--mantine-color-white))'
				>
					<ThemeIcon
						size={iconSize}
						variant='transparent'
						color='light-dark(var(--mantine-color-black), var(--mantine-color-white))'
					>
						<AppleLogoIcon size={'100%'} style={{ paddingBottom: rem(3) }} />
					</ThemeIcon>
					<Title order={1} size={labelSize} fw={600} textWrap='nowrap'>
						{firstWord}
					</Title>
					<Title
						order={1}
						size={labelSize}
						fw={600}
						textWrap='nowrap'
						c='light-dark(var(--mantine-color-blue-6), var(--mantine-color-blue-4))'
					>
						{words?.join(' ')}
					</Title>
				</Group>
			);
		}

		return (
			<ThemeIcon size={iconSize} variant='transparent' color='dark'>
				<AppleLogoIcon size={'80%'} />
			</ThemeIcon>
		);
	}

	if (toProps.to === undefined) {
		return <Logo />;
	}

	return (
		<Link
			{...toProps}
			c='rgba(255, 255, 255, 1)'
			aria-label={label ?? 'Apple Logo'}
			underline={'never'}
		>
			<Logo />
		</Link>
	);
}

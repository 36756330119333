import { queryOptions } from '@tanstack/react-query';

import type { QueryOptions } from '@apple/lib/tanstack-query';

import { getComments, getPurchaseOrder, getPurchaseOrderLines } from '../api/details';
import type { Comment, PurchaseOrderLine, PurchaseOrderResponse } from '../models/details';

export const purchaseOrderQueryOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['purchase-order', poNumber],
		queryFn: () => getPurchaseOrder(poNumber),
		staleTime: 5 * 60 * 1000, // 5 minute
	}) as QueryOptions<PurchaseOrderResponse>;

export const purchaseOrderLinesQueryOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['purchase-order', poNumber, 'lines'],
		queryFn: () => getPurchaseOrderLines(poNumber),
		staleTime: 5 * 60 * 1000, // 5 minute
	}) as QueryOptions<PurchaseOrderLine[]>;

export const commentsQueryOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['purchase-order', poNumber, 'comments'],
		queryFn: () => getComments(poNumber),
		staleTime: 5 * 60 * 1000, // 5 minute
	}) as QueryOptions<Comment[]>;

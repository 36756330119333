import { forwardRef } from 'react';
import { Loader } from '@mantine/core';

import { LoaderIcon } from '@apple/assets/icons';

export const LoaderTheme = Loader.extend({
	defaultProps: {
		loaders: { ...Loader.defaultLoaders, ring: forwardRef(LoaderIcon) },
		type: 'oval',
	},
});

import { Box } from '@mantine/core';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { createRouter } from '@tanstack/react-router';

import { profileQueryOptions } from '@apple/features/auth';
import { routeTree } from '@apple/retail/routes.gen';
import { RouterErrorFallback } from '@apple/ui/error';
import { LoadingOverlay } from '@apple/ui/loading-overlay';
import { NotFoundPage } from '@apple/ui/shell/pages/NotFoundPage';
import { NotAuthenticatedError } from '@apple/utils/api';

export type AppleRetailRouter = typeof router;

const handleUnauthenticated = (error: Error): Promise<void> => {
	if (
		!(error instanceof NotAuthenticatedError) ||
		router.matchRoute({ to: '/login' }) ||
		router.matchRoute({ to: '/session-expired' }) ||
		router.matchRoute({ to: '/reset-password' }) ||
		router.matchRoute({ to: '/session-expired' }) ||
		// If we're already on the logout page or session-expired page, don't redirect to the session expired page
		router.latestLocation.pathname === '/logout' ||
		router.latestLocation.pathname === '/session-expired'
	) {
		return Promise.resolve();
	}
	return router.navigate({
		to: '/session-expired',
		search: { returnUrl: router.latestLocation.pathname },
	});
};

export const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (error, query) => {
			if (query.queryKey === profileQueryOptions(true).queryKey) {
				return;
			}
			void handleUnauthenticated(error);
		},
	}),
	mutationCache: new MutationCache({ onError: handleUnauthenticated }),
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
});

export const router = createRouter({
	routeTree,
	defaultPreload: false,
	defaultPendingComponent: () => {
		// Relative position keeps the loading overlay within the main content area
		return (
			<Box pos='relative'>
				<LoadingOverlay visible name='router' />
			</Box>
		);
	},
	defaultNotFoundComponent: NotFoundPage,
	defaultErrorComponent: RouterErrorFallback,
	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	// https://tanstack.com/router/latest/docs/framework/react/guide/data-loading#passing-all-loader-events-to-an-external-cache
	defaultPreloadStaleTime: 0,
	//Wrap: ({ children }) => <AppProviders>{children}</AppProviders>,
	context: {
		queryClient,
		auth: undefined!, // This will be set after we wrap the app in an AuthProvider
		lang: undefined!, // This will be set after we wrap the app in a LangProvider
	},
});

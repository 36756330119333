import { generateColors } from '@mantine/colors-generator';
import { createTheme, Modal, MultiSelect, rem, Select } from '@mantine/core';
import { DateInput, DatePickerInput } from '@mantine/dates';
import type {
	AppShellNavbarConfiguration,
	CSSProperties,
	MantineThemeOverride,
} from '@mantine/core';
import type { TFunction } from 'i18next';

import { InputTheme } from '@apple/ui/layouts/components/input';

import { AppShellTheme } from './components/appshell';
import { CardTheme } from './components/card';
import { IndicatorTheme } from './components/indicator';
import { LoaderTheme } from './components/loader';
import { OverlayTheme } from './components/overlay';
import { TableTheme } from './components/table';
import { colors } from './tokens/colors';
import { materials } from './tokens/materials';
import { bodyFontFamlies, fonts, titleFontFamlies } from './tokens/typography';

declare module '@mantine/core' {
	interface MantineThemeOther {
		materials: typeof materials;
		colors: typeof colors;
		fonts: typeof fonts;
		page: {
			minWidth: CSSProperties['minWidth'];
		};
		headerHeight: string;
		footerHeight: string;
		headerBackgroundColor: CSSProperties['backgroundColor'];
		headerBackgroundColorDark: CSSProperties['backgroundColor'];
		headerBackdropFilter: CSSProperties['backdropFilter'];
		controlAccentColor: CSSProperties['color'];

		primarySidebar: {
			width: AppShellNavbarConfiguration['width'];
			rowHeight: {
				sm: CSSProperties['height'];
				md: CSSProperties['height'];
				lg: CSSProperties['height'];
			};
			iconSize: {
				sm: CSSProperties['height'];
				md: CSSProperties['height'];
				lg: CSSProperties['height'];
			};
			textSize: {
				sm: CSSProperties['fontSize'];
				md: CSSProperties['fontSize'];
				lg: CSSProperties['fontSize'];
			};
			cellSpacing: {
				horizontal: CSSProperties['paddingLeft'];
				vertical: CSSProperties['paddingTop'];
			};
		};
		secondarySidebar: {
			width: AppShellNavbarConfiguration['width'];
		};

		getOrderStatusColor: (
			status: string | undefined,
			pendingCancellation: boolean,
			t: TFunction<'order'>,
		) => CSSProperties['color'];
	}
}

// + Finish setting up theme
// https://mantine.dev/theming/default-theme/
// https://developer.apple.com/design/human-interface-guidelines/color#Specifications
// https://developer.apple.com/design/human-interface-guidelines/dark-mode
export const appleTheme: MantineThemeOverride = createTheme({
	// "scale": 1,
	// "fontSmoothing": true,
	// "focusRing": "auto",
	// autoContrast: true,
	colors: {
		blue: generateColors(colors.system.blueLight),
		cyan: generateColors(colors.system.tealLight),
		green: generateColors(colors.system.greenLight),
		indigo: generateColors(colors.system.indigoDark),
		teal: generateColors(colors.system.tealLight),
		orange: generateColors(colors.system.orangeLight),
		pink: generateColors(colors.system.pinkLight),
		purple: generateColors(colors.system.purpleLight),
		red: generateColors(colors.system.redLight),
		yellow: generateColors(colors.system.yellowLight),
		gray: [
			colors.system.gray6Light,
			colors.system.gray5Light,
			colors.system.gray4Light,
			colors.system.gray3Light,
			colors.system.gray2Light,
			colors.system.gray1Light,
			colors.system.gray2Dark,
			colors.system.gray3Dark,
			colors.system.gray4Dark,
			colors.system.gray5Dark,
			colors.system.gray6Dark,
		],
	},
	// primaryShade: {
	// 	light: 5,
	// 	dark: 7,
	// },
	fontFamily: bodyFontFamlies,

	// Controls the default cursor type for interactive elements, that do not have
	// cursor: pointer styles by default. For example, `Checkbox` and `NativeSelect`.
	// "cursorType": "default",
	cursorType: 'pointer',
	// "defaultGradient": {
	//   "from": "blue",
	//   "to": "cyan",
	//   "deg": 45
	// },
	defaultRadius: 'md',
	// "activeClassName": "mantine-active",
	// "focusClassName": "",
	headings: {
		fontFamily: titleFontFamlies,
		sizes: {
			h1: {
				fontSize: fonts.largeTitle.fontSize,
				lineHeight: fonts.largeTitle.lineHeight,
				fontWeight: fonts.largeTitle.fontWeightBold,
			},
			h2: {
				fontSize: fonts.title1.fontSize,
				lineHeight: fonts.title1.lineHeight,
				fontWeight: fonts.title1.fontWeightBold,
			},
			h3: {
				fontSize: fonts.title2.fontSize,
				lineHeight: fonts.title2.lineHeight,
				fontWeight: fonts.title2.fontWeightBold,
			},
			h4: {
				fontSize: fonts.title3.fontSize,
				lineHeight: fonts.title3.lineHeight,
				fontWeight: fonts.title3.fontWeightBold,
			},
			h5: {
				fontSize: fonts.headline.fontSize,
				lineHeight: fonts.headline.lineHeight,
				fontWeight: fonts.headline.fontWeightBold,
			},
			h6: {
				fontSize: fonts.body.fontSize,
				lineHeight: fonts.body.lineHeight,
				fontWeight: fonts.body.fontWeightBold,
			},
		},
	},
	fontSizes: {
		xs: fonts.subheadline.fontSize,
		sm: fonts.callout.fontSize,
		md: fonts.body.fontSize,
		lg: fonts.headline.fontSize,
		xl: fonts.title3.fontSize,
	},
	lineHeights: {
		xs: fonts.subheadline.lineHeight,
		sm: fonts.callout.lineHeight,
		md: fonts.body.lineHeight,
		lg: fonts.headline.lineHeight,
		xl: fonts.title3.lineHeight,
	},
	// TODO: I don't think the postcss breakpoints are working because customizing these causes weird behaviors between the modified breakpoints...
	// breakpoints: {
	// 	xs: '36em',
	// 	sm: '48em',
	// 	md: '64em', // Default: 62em
	// 	lg: '75em',
	// 	xl: '90em',
	// },
	// "shadows": {
	//   "xs": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), 0 calc(0.0625rem * var(--mantine-scale)) calc(0.125rem * var(--mantine-scale)) rgba(0, 0, 0, 0.1)",
	//   "sm": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(0.625rem * var(--mantine-scale)) calc(0.9375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.4375rem * var(--mantine-scale)) calc(0.4375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))",
	//   "md": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(1.25rem * var(--mantine-scale)) calc(1.5625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.625rem * var(--mantine-scale)) calc(0.625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))",
	//   "lg": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(1.75rem * var(--mantine-scale)) calc(1.4375rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.75rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))",
	//   "xl": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(2.25rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(1.0625rem * var(--mantine-scale)) calc(1.0625rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))"
	// },
	other: {
		colors,
		materials,
		fonts,
		page: {
			minWidth: rem(380),
		},
		headerHeight: rem(44),
		footerHeight: rem(84),
		headerForegroundColor: 'rgba(255, 255, 255, .8)',
		headerForegroundHoverColor: 'rgba(255, 255, 255, 1)',
		headerBackgroundColor: 'rgba(22, 22, 23, .8)',
		headerBackgroundColorDark: 'rgba(0, 0, 0, .7)',
		headerBackdropFilter: 'saturate(180%) blur(30px)',
		controlAccentColor: 'rgb(10, 132, 255)',
		primarySidebar: {
			width: 260,
			rowHeight: {
				sm: '24pt',
				md: '28pt',
				lg: '32pt',
			},
			iconSize: {
				sm: 16,
				md: 20,
				lg: 24,
			},
			textSize: {
				sm: '11pt',
				md: '13pt',
				lg: '15pt',
			},
			cellSpacing: {
				horizontal: '17pt',
				vertical: 0,
			},
		},
		secondarySidebar: {
			width: 380,
		},
		getOrderStatusColor: (status, pendingCancellation, t) => {
			if (pendingCancellation) {
				return 'light-dark(var(--mantine-color-yellow-7), var(--mantine-color-yellow-5))';
			}

			// This is a bit of a hack since the backend doesn't return the status as an enum...
			switch (status) {
				case t('orderStatus.inProcess'):
				case t('orderStatus.shipped'):
					return 'light-dark(var(--mantine-color-green-7), var(--mantine-color-green-5))';
				case t('orderStatus.rejected'):
					return 'light-dark(var(--mantine-color-red-7), var(--mantine-color-red-5))';
				case t('orderStatus.orderReview'):
					return 'light-dark(var(--mantine-color-yellow-8), var(--mantine-color-yellow-5))';
				case t('orderStatus.cancelled'):
					return 'light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-5))';
				case t('orderStatus.backordered'):
					return 'light-dark(var(--mantine-color-red-7), var(--mantine-color-red-5))';
				default:
					return 'light-dark(var(--mantine-color-blue-7), var(--mantine-color-blue-5))';
			}
		},
	},
	components: {
		AppShell: AppShellTheme,
		Card: CardTheme,
		Loader: LoaderTheme,
		Indicator: IndicatorTheme,
		Input: InputTheme,
		Select: Select.extend({
			defaultProps: {
				checkIconPosition: 'right',
			},
		}),
		MultiSelect: MultiSelect.extend({
			vars: () => ({
				wrapper: {
					'--input-size': 'var(--input-height)',
					'--input-padding-y': '0',
				},
			}),
		}),

		DateInput: DateInput.extend({
			styles: {
				input: {
					height: 'var(--input-size)',
				},
			},
		}),

		DatePickerInput: DatePickerInput.extend({
			styles: {
				input: {
					height: 'var(--input-size)',
				},
			},
		}),

		Table: TableTheme,
		Overlay: OverlayTheme,

		Modal: Modal.extend({
			styles: (theme, props, ctx) => ({
				header: {
					backgroundColor: materials.chromeDark.background,
					backgroundBlendMode: materials.chromeDark.backgroundBlendMode,
					backdropFilter: materials.chromeDark.backdropFilter,
				},
				body: {
					backgroundColor: materials.regularDark.background,
					backgroundBlendMode: materials.regularDark.backgroundBlendMode,
					backdropFilter: materials.regularDark.backdropFilter,
				},
			}),
		}),

		// Popover: Popover.extend({
		// 	vars: (theme, props) => {
		// 		return {
		// 			//FIXME: The popover background color customization is not working
		// 			dropdown: {
		// 				'--_popover-bg':
		// 					'light-dark(var(--mantine-color-white), var(--mantine-color-dark-8))',
		// 			} as unknown as any,
		// 		};
		// 	},
		// }),
	},
});

import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import moment from 'moment';
import type { OdataQueryBuilder } from 'odata-builder';

import { createDataTableQueryOptionsSchema } from '@apple/ui/data-table';
import { execODataQuery } from '@apple/utils/api';
import type { QueryOptions } from '@apple/lib/tanstack-query';
import type { DataTableQueryOptions } from '@apple/ui/data-table';
import type { PageState } from '@apple/utils/pagination';

import { userFiltersSchema, userListingSchema } from '../models/listing';
import type { UserFilters, UserListing } from '../models/listing';

const userQueryOptionsSchema = createDataTableQueryOptionsSchema<UserFilters>(userFiltersSchema);

export function getUserDataTableQueryOptions(options: DataTableQueryOptions<UserFilters>) {
	const { pagination, filters } = userQueryOptionsSchema.parse(options);
	return queryOptions({
		queryKey: ['users-odata', filters, pagination],
		queryFn: async ({ signal }) =>
			await execODataQuery<UserListing>({
				urlPath: '/odata/management/users',
				rowSchema: userListingSchema,
				builderFn: odataBuilder => buildUserODataQuery(odataBuilder, options.filters),
				pagination,
				signal,
			}),
		placeholderData: keepPreviousData,
		throwOnError: true,
		retry: false,
	}) as QueryOptions<PageState<UserListing>>;
}

// TODO: Find a better way to map filters and sorting to the odata builder
function buildUserODataQuery(builder: OdataQueryBuilder<UserListing>, filters: UserFilters) {
	builder.orderBy({
		field: 'username',
		orderDirection: 'asc',
	});

	if (filters.isActive !== undefined) {
		builder.filter({
			field: 'isActive',
			operator: 'eq',
			value: filters.isActive === 'active',
		});
	}

	if (filters.username !== undefined) {
		builder.filter({
			filters: [
				{
					field: 'username',
					operator: 'contains',
					value: encodeURIComponent(filters.username),
					ignoreCase: true,
				},
				{
					field: 'email',
					operator: 'contains',
					value: encodeURIComponent(filters.username),
					ignoreCase: true,
				},
				{
					field: 'firstName',
					operator: 'contains',
					value: encodeURIComponent(filters.username),
					ignoreCase: true,
				},
				{
					field: 'lastName',
					operator: 'contains',
					value: encodeURIComponent(filters.username),
					ignoreCase: true,
				},
			],
			logic: 'or',
		});
	}

	if (filters.userRoles !== undefined) {
		builder.filter({
			field: 'userRoles',
			operator: 'eq',
			ignoreCase: true,
			value: filters.userRoles,
		});
	}

	if (filters.lastSignInDate !== undefined && Array.isArray(filters.lastSignInDate)) {
		const [startDate, endDate] = filters.lastSignInDate;

		builder.filter({
			field: 'lastSignInDate',
			operator: 'ge',
			value: moment(startDate).startOf('day').toDate(),
		});

		builder.filter({
			field: 'lastSignInDate',
			operator: 'le',
			value: moment(endDate).endOf('day').toDate(),
		});
	}

	if (filters.createDate !== undefined && Array.isArray(filters.createDate)) {
		const [startDate, endDate] = filters.createDate;

		builder.filter({
			field: 'createDate',
			operator: 'ge',
			value: moment(startDate).startOf('day').toDate(),
		});

		builder.filter({
			field: 'createDate',
			operator: 'le',
			value: moment(endDate).endOf('day').toDate(),
		});
	}

	if (filters.orderValueLimit !== undefined && Array.isArray(filters.orderValueLimit)) {
		const [minQuantity, maxQuantity] = filters.orderValueLimit;
		if (minQuantity !== undefined && minQuantity !== null && minQuantity !== '') {
			builder.filter({
				field: 'orderValueLimit',
				operator: 'ge',
				value: Number(minQuantity),
			});
		}
		if (maxQuantity !== undefined && maxQuantity !== null && maxQuantity !== '') {
			builder.filter({
				field: 'orderValueLimit',
				operator: 'le',
				value: Number(maxQuantity),
			});
		}
	}
}

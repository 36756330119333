import { useCallback } from 'react';
import { ActionIcon, Indicator } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { icons } from '@apple/assets';

import { useActiveAnnouncements } from '../hooks/useActiveAnnouncements';

export function NotificationMenu() {
	const activeAnnouncements = useActiveAnnouncements();

	const handleClick = useCallback(() => {
		notifications.show({
			title: 'Notifications',
			message: 'Not implemented yet',
		});
	}, []);

	const notificationCount =
		!activeAnnouncements.isLoading && !activeAnnouncements.error && activeAnnouncements.response
			? activeAnnouncements.response.unreadCount
			: 0;

	// TODO: Fix the icon color
	return (
		<Indicator
			data-testid='notification-badge'
			label={notificationCount}
			disabled={notificationCount <= 0}
			inline
		>
			<ActionIcon
				data-testid='notification-icon'
				variant='transparent'
				onClick={handleClick}
				c={'light-dark(var(--mantine-color-black), var(--mantine-color-white))'}
			>
				<icons.Notifications size='70%' />
			</ActionIcon>
		</Indicator>
	);
}

import { useMemo, useState } from 'react';
import { Anchor, Divider, Group, Select, TextInput } from '@mantine/core';
import type { MantineStyleProp } from '@mantine/core';

import { Search } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import type { Plant } from '@apple/features/plants/models';
import type { Program } from '@apple/features/program/models';

export type CountryFilter = {
	plant: string;
	searchText: string;
};

export function ProgramCountryFilter({
	plants,
	program,
	onFilterChanged,
	handleToggleFilteredCountries,
}: {
	plants: Plant[];
	program: Program;
	onFilterChanged: (filter: CountryFilter) => void;
	handleToggleFilteredCountries: (selected: boolean) => void;
}) {
	const [filter, setFilter] = useState<CountryFilter>({ searchText: '', plant: '' });
	const filterSelected = useMemo(() => filter.plant === '' && filter.searchText === '', [filter]);
	const { t } = useTranslation('product');

	const anchorStyle = (isDisabled: boolean): MantineStyleProp => ({
		pointerEvents: isDisabled ? 'none' : 'auto',
		cursor: isDisabled ? 'default' : 'pointer',
		color: isDisabled ? 'black' : 'blue',
	});

	return (
		<Group>
			<Select
				data={plants
					.filter(x => x.countryCodes.some(y => program.countries.includes(y)))
					.map(x => x.name)}
				onChange={plant => {
					onFilterChanged({ ...filter, plant: plant ?? '' });
					setFilter({ ...filter, plant: plant ?? '' });
				}}
				data-testid={`${program.code} select-plant`}
				clearable
				placeholder={t('associations.labels.selectPlant')}
			></Select>

			<Anchor
				data-testid={`${program.code} select-All`}
				style={anchorStyle(filterSelected)}
				onClick={() => {
					handleToggleFilteredCountries(true);
				}}
			>
				{t('associations.buttons.selectAll')}
			</Anchor>
			<Divider orientation='vertical' />
			<Anchor
				data-testid={`${program.code} deselect-All`}
				style={anchorStyle(filterSelected)}
				onClick={() => {
					handleToggleFilteredCountries(false);
				}}
			>
				{t('associations.buttons.deselectAll')}
			</Anchor>
			<TextInput
				data-testid={`${program.code} search-Country`}
				leftSection={<Search />}
				placeholder={t('associations.labels.searchCountry')}
				onChange={value => {
					setFilter({ ...filter, searchText: value.currentTarget.value });
					onFilterChanged({
						...filter,
						searchText: value.currentTarget.value,
					});
				}}
				value={filter.searchText}
			></TextInput>
		</Group>
	);
}

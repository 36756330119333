import { queryOptions } from '@tanstack/react-query';

import {
	getAvailableShippingOptions,
	getItemsUpdatedCount,
	getLocationSelections,
	getOrCreateBulkOrder,
	getOrderItems,
	getOrderTypes,
	getProductSelections,
	getSavedBulkOrders,
	getSelectedAddresses,
	getSelectedLocationsPlantCount,
	getSelectedPrograms,
} from './api';
import type { CartId } from './models/models';

export const cartQueryKeys = {
	all: ['cart'],
	types: ['cart', 'order'],
	saved: ['cart', 'saved-orders'],
	shippingOptions: ['cart', 'shipping-options'],
	cartId: ['cart', 'id'],
	cart: (cartId: CartId) => ['cart', cartId] as const,
	items: (cartId: CartId) => ['cart', cartId, 'items'] as const,
	itemCount: (cartId: CartId | null) => ['cart', cartId, 'items', 'count'] as const,
	locations: (cartId: CartId) => ['cart', cartId, 'locations'] as const,
	addresses: (cartId: CartId) => ['cart', cartId, 'addresses'] as const,
	submittedLines: (cartId: CartId) => ['cart', cartId, 'lines', 'submitted'] as const,
} as const;

export const cartQueryOptions = {
	types: queryOptions({
		queryKey: cartQueryKeys.types,
		queryFn: ({ signal }) => getOrderTypes(signal),
		staleTime: 60 * 5 * 1000,
	}),

	saved: queryOptions({
		queryKey: cartQueryKeys.saved,
		queryFn: () => getSavedBulkOrders(),
		staleTime: 60 * 5 * 1000,
	}),

	shippingOptions: queryOptions({
		queryKey: cartQueryKeys.shippingOptions,
		queryFn: () => getAvailableShippingOptions(),
		staleTime: 60 * 5 * 1000,
	}),

	itemCount: ({ cartId, enabled }: { cartId?: CartId; enabled: boolean }) =>
		queryOptions({
			queryKey: cartQueryKeys.itemCount(cartId ?? null),
			queryFn: ({ signal }) => (!cartId ? 0 : getItemsUpdatedCount(cartId, signal)),
			enabled,
			staleTime: 30 * 1000,
		}),

	cartId: (enabled: boolean) =>
		queryOptions({
			queryKey: cartQueryKeys.cartId,
			queryFn: ({ signal }) => getOrCreateBulkOrder(signal),
			enabled,
			staleTime: 30 * 1000,
		}),

	items: (cartId: CartId) =>
		queryOptions({
			queryKey: cartQueryKeys.items(cartId),
			queryFn: async ({ signal }) => {
				const products = await getProductSelections(cartId, signal);

				const itemResponse =
					products.length === 0
						? undefined
						: await getOrderItems(
								{
									bulkOrderId: cartId,
									areZeroQuantityLineItemsExcluded: false,
								},
								signal,
							);

				return {
					cartId,
					products,
					items: itemResponse?.locationOrderItems ?? [],
					salesOrderNumber: itemResponse?.salesOrderNumber,
					referenceNumber: itemResponse?.referenceNumber,
					totalQuantityCount: itemResponse?.totalQuantityCount,
				};
			},
			staleTime: 30 * 1000,
		}),

	locations: (cartId: CartId) =>
		queryOptions({
			queryKey: cartQueryKeys.locations(cartId),
			queryFn: async ({ signal }) => {
				const [locations, programs, plantCount] = await Promise.all([
					getLocationSelections(cartId, signal),
					getSelectedPrograms(cartId, signal),
					getSelectedLocationsPlantCount(cartId, signal),
				]);

				return {
					locations,
					programs,
					plantCount,
				};
			},
			staleTime: 30 * 1000,
		}),

	addresses: (cartId: CartId) =>
		queryOptions({
			queryKey: cartQueryKeys.addresses(cartId),
			queryFn: ({ signal }) => getSelectedAddresses(cartId, signal),
			staleTime: 30 * 1000,
		}),

	submittedCart: (cartId: CartId) =>
		queryOptions({
			queryKey: cartQueryKeys.submittedLines(cartId),
			queryFn: async ({ signal }) => {
				const [addresses, items] = await Promise.all([
					getSelectedAddresses(cartId, signal),
					getOrderItems(
						{
							bulkOrderId: cartId,
							areZeroQuantityLineItemsExcluded: false,
						},
						signal,
					),
				]);

				return {
					cartId,
					addresses,
					items: items?.locationOrderItems ?? [],
					salesOrderNumber: items?.salesOrderNumber,
					referenceNumber: items?.referenceNumber,
					totalQuantityCount: items?.totalQuantityCount,
				};
			},
			staleTime: 30 * 1000,
		}),
};

import { z } from 'zod';

import { OrderCommentStatus } from '@apple/features/order/models/search';

export type ApproveOrdersResponse = z.infer<typeof approveOrdersResponseSchema>;
export type ApprovalOrdersRequestOrder = z.infer<typeof approvalOrdersRequestOrderSchema>;
export type LineItemPositionQuantity = z.infer<typeof lineItemPositionQuantitySchema>;
export type PurchaseOrderUpdate = z.infer<typeof purchaseOrderUpdateSchema>;
export type ApproveOrdersRequest = z.infer<typeof approveOrdersRequestSchema>;
export type RejectOrdersRequest = z.infer<typeof rejectOrdersRequestSchema>;
export type ReviewOrdersRequest = z.infer<typeof reviewOrdersRequestSchema>;
export type OrderApprovalReason = z.infer<typeof orderApprovalReasonSchema>;
export type ApprovalDetailResponse = z.infer<typeof approvalDetailResponseSchema>;
export type ApproveQuantityUpdateResponse = z.infer<typeof approveQuantityUpdateResponseSchema>;

export interface OrderApprovalListing {
	purchaseOrderNumber: string;
	salesOrderNumber: string;
	orderDate: Date;
	orderCommentStatus: OrderCommentStatus;
	hasComments: boolean;
	orderedBy: string;
	userRole: string;
	orderedByEmailAddress: string;
	orderedByFirstName: string;
	orderedByLastName: string;
	countryCode: string;
	customerGroupName: string;
	plantName: string;
	totalPrice: number;
	usersCurrencyCode: string;
	totalPriceUSD: number;
	reasonsForApproval?: string;
	reasonsForApprovalList: string[];
	appleId?: string;
}

export const orderCommentStatusSchema = z.nativeEnum(OrderCommentStatus);

export const orderApprovalListingSchema = z.object({
	purchaseOrderNumber: z.string(),
	salesOrderNumber: z.string(),
	orderDate: z.instanceof(Date),
	orderCommentStatus: orderCommentStatusSchema,
	hasComments: z.boolean(),
	orderedBy: z.string(),
	userRole: z.string(),
	orderedByEmailAddress: z.string(),
	orderedByFirstName: z.string(),
	orderedByLastName: z.string(),
	countryCode: z.string(),
	customerGroupName: z.string(),
	plantName: z.string(),
	totalPrice: z.number(),
	usersCurrencyCode: z.string(),
	totalPriceUSD: z.number(),
	reasonsForApproval: z.string().optional(),
	reasonsForApprovalList: z.array(z.string()),
	appleId: z.string().optional(),
}) satisfies z.ZodType<OrderApprovalListing>;

export const approveOrdersResponseSchema = z.object({
	failedOrderNumbers: z.array(z.string()),
	successfulOrderNumbers: z.array(z.string()),
});

export const approvalOrdersRequestOrderSchema = z.object({
	purchaseOrderNumber: z.string(),
	salesOrderNumber: z.string(),
});

export const lineItemPositionQuantitySchema = z.object({
	lineItemPosition: z.number(),
	quantity: z.number(),
});

export const purchaseOrderUpdateSchema = z.object({
	purchaseOrderNumber: z.string(),
	salesOrderNumber: z.string(),
	countryCode: z.string(),
	shippingOptionId: z.number(),
	orderStatus: z.null(),
	lineItemPositionQuantities: z.array(lineItemPositionQuantitySchema),
	updatedDate: z.instanceof(Date),
});

export const approveOrdersRequestSchema = z.object({
	orderNumbers: z.array(approvalOrdersRequestOrderSchema),
	purchaseOrderUpdate: purchaseOrderUpdateSchema.nullable(),
});

export const rejectOrdersRequestSchema = z.object({
	orderNumbers: z.array(approvalOrdersRequestOrderSchema),
});

export const reviewOrdersRequestSchema = z.object({
	orderNumbers: z.array(approvalOrdersRequestOrderSchema),
});

export const lineTotalSchema = z.object({
	Key: z.number(),
	Value: z.string(),
});

export const approveQuantityUpdateResponseSchema = z.object({
	orderTotal: z.string(),
	requiresApproval: z.boolean(),
	isOrderOverValue: z.boolean(),
	lineTotals: z.array(lineTotalSchema),
	isOrderOverBudget: z.boolean(),
	formattedTotalOverBudget: z.string(),
});

export const orderApprovalReasonSchema = z.enum([
	'ApprovalReasonRequiresReview',
	'ApprovalReasonExpeditedShipping',
	'ApprovalReasonItemQuantityLimit',
	'ApprovalReasonOrderValueLimit',
	'RequiresAdminApproval',
]);

export const approvalDetailResponseSchema = z.object({
	isApprovalRequired: z.boolean(),
	orderApprovalReasons: z.array(orderApprovalReasonSchema),
	isOrderOverBudget: z.boolean(),
	formattedTotalOverBudget: z.string(),
});

import { memo, useEffect } from 'react';
import { Input } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { Link } from '@mantine/tiptap';
import { TextAlign } from '@tiptap/extension-text-align';
import { Underline } from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import type { Editor } from '@tiptap/react';

import { useTranslation } from '@apple/lib/i18next';
import { TextEditor } from '@apple/ui/form';

type AnnouncementLocalizationContentProps = {
	culture: string;
	currentLocalization: { culture: string; content: string };
	onLocalizationChange: (culture: string, content: string) => void;
};

export const AnnouncementLocalizationContent = memo(
	({
		culture,
		currentLocalization,
		onLocalizationChange,
	}: AnnouncementLocalizationContentProps) => {
		const { t } = useTranslation('content');

		const editor = useEditor({
			extensions: [
				StarterKit,
				Underline,
				Link,
				TextAlign.configure({ types: ['heading', 'paragraph'] }),
			],
			content: currentLocalization.content,
		});

		const handleDescriptionUpdate = useDebouncedCallback((editor: Editor) => {
			const content = editor.getHTML();
			const text = editor.getText();

			onLocalizationChange(culture, text ? content : '');
		}, 500);

		useEffect(() => {
			if (editor) {
				editor.on('update', () => handleDescriptionUpdate(editor));
			}

			return () => {
				editor && editor.off('update', () => handleDescriptionUpdate(editor));
			};
		}, [editor, handleDescriptionUpdate]);

		useEffect(() => {
			if (editor && editor.getHTML() !== currentLocalization.content) {
				editor.commands.setContent(currentLocalization.content);
			}
		}, [currentLocalization.content, editor]);

		return (
			<>
				{editor && (
					<Input.Wrapper label={t('announcements.labels.content')}>
						<TextEditor editor={editor} />
					</Input.Wrapper>
				)}
			</>
		);
	},
);

import {
	Divider,
	Group,
	Table as MantineTable,
	Pagination,
	Progress,
	rem,
	Select,
	Text,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { flexRender } from '@tanstack/react-table';
import type { CSSProperties } from '@mantine/core';
import type { RowData, Table } from '@tanstack/react-table';

import { SortAscIcon, SortDescIcon } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';

import type { TableVariant } from '../types';

// eslint-disable-next-line react-refresh/only-export-components
export const PAGINATION_OPTIONS = [
	{ value: '25', label: '25' },
	{ value: '50', label: '50' },
	{ value: '100', label: '100' },
] as const;

export function DataTable<T extends RowData>({
	table,
	loading = false,
	hidePageSize = false,
	hidePagination = false,
	minWidth = '1200px',
	variant = 'apple-table',
}: {
	table: Table<T>;
	loading?: boolean;
	hidePageSize?: boolean;
	hidePagination?: boolean;
	minWidth?: CSSProperties['minWidth'];
	variant?: TableVariant;
}) {
	const { t } = useTranslation('controls');
	const [loadingDebounced] = useDebouncedValue(loading, 300);

	return (
		<>
			<MantineTable.ScrollContainer minWidth={minWidth} maw='100%' h='100%'>
				<TableBody table={table} variant={variant} />
				{loadingDebounced ? <Progress radius='xl' size='xs' value={100} animated /> : null}
			</MantineTable.ScrollContainer>

			<TablePagingButtons
				table={table}
				hidePageSize={hidePageSize}
				hidePagination={hidePagination}
				pageSizeLabel={t('dataTable.pageSize')}
			/>
		</>
	);

	// return (
	// 	<Stack
	// 		align='stretch'
	// 		justify='space-between'
	// 		h='100%'
	// 		w='100%'
	// 		mih='inherit'
	// 		gap={0}
	// 		style={{
	// 			flex: 1,
	// 			transitionProperty: 'flex',
	// 			transitionDuration: theme.other.dataTable.filterTransitionMs.toString() + 'ms',
	// 			direction: table.options.columnResizeDirection,
	// 		}}
	// 	>
	// 		<Stack
	// 			align='stretch'
	// 			justify='flex-start'
	// 			gap={0}
	// 			style={{
	// 				direction: table.options.columnResizeDirection,
	// 			}}
	// 		>
	// 			<MantineTable.ScrollContainer minWidth={minWidth} maw='100%'>
	// 				<TableBody table={table} variant={variant} />
	// 			</MantineTable.ScrollContainer>
	// 		</Stack>

	// 		{loadingDebounced ? <Progress radius='xl' size='xs' value={100} animated /> : null}

	// 		<TablePagingButtons
	// 			table={table}
	// 			hidePageSize={hidePageSize}
	// 			hidePagination={hidePagination}
	// 			pageSizeLabel={t('dataTable.pageSize')}
	// 		/>
	// 	</Stack>
	// );
}

export function TableBody<TRow>({
	table,
	striped = true,
	variant = undefined,
}: {
	table: Table<TRow>;
	striped?: boolean;
	variant?: TableVariant;
}) {
	return (
		<MantineTable
			striped={striped}
			highlightOnHover
			stickyHeader
			variant={variant}
			// w={table.getTotalSize()}
		>
			<MantineTable.Thead bg='light-dark(white, var(--mantine-color-dark-9))'>
				{table.getHeaderGroups().map(headerGroup => (
					<MantineTable.Tr key={headerGroup.id}>
						{headerGroup.headers
							.filter(x => !x.column.columnDef.meta?.hideColumn)
							.map(header => (
								<MantineTable.Th
									key={header.id}
									colSpan={header.colSpan}
									w={header.getSize()}
									pos='relative'
									onClick={header.column.getToggleSortingHandler()}
									style={{
										textWrap: 'nowrap',
										cursor: header.column.getCanSort() ? 'pointer' : 'auto',
										userSelect: 'none',
									}}
								>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
									<Group
										pos='absolute'
										top='0'
										right={0}
										h='100%'
										justify='end'
										align='center'
									>
										{{
											asc: <SortAscIcon />,
											desc: <SortDescIcon />,
											false: null,
										}[header.column.getIsSorted() as string] ?? null}
										{header.column.getIsLastColumn() ? null : (
											<Divider
												size='xs'
												orientation='vertical'
												c='white'
												my={6}
												onMouseDown={header.getResizeHandler()} //for desktop
												onTouchStart={header.getResizeHandler()} //for mobile
												onDoubleClick={() => header.column.resetSize()}
												style={{
													cursor: 'col-resize',
													userSelect: 'none',
													touchAction: 'none',
													transform:
														table.options.columnResizeMode ===
															'onEnd' && header.column.getIsResizing()
															? `translateX(${
																	(table.options
																		.columnResizeDirection ===
																	'rtl'
																		? -1
																		: 1) *
																	(table.getState()
																		.columnSizingInfo
																		.deltaOffset ?? 0)
																}px)`
															: '',
												}}
											/>
										)}
									</Group>
								</MantineTable.Th>
							))}
					</MantineTable.Tr>
				))}
			</MantineTable.Thead>
			<MantineTable.Tbody>
				{table.getRowModel().rows.map(row => (
					<MantineTable.Tr key={row.id}>
						{row
							.getVisibleCells()
							.filter(cell => !cell.column.columnDef.meta?.hideColumn)
							.map(cell => (
								<MantineTable.Td key={cell.id} w={cell.column.getSize()}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</MantineTable.Td>
							))}
					</MantineTable.Tr>
				))}
			</MantineTable.Tbody>
		</MantineTable>
	);
}

function TablePagingButtons<TRow>({
	table,
	pageSizeLabel,
	hidePageSize,
	hidePagination,
}: {
	table: Table<TRow>;
	pageSizeLabel: string;
	hidePageSize: boolean;
	hidePagination: boolean;
}) {
	return (
		<Group justify='end' align='center' p='lg'>
			{!hidePageSize && (
				<>
					<Text>{pageSizeLabel}</Text>
					<Select
						data={PAGINATION_OPTIONS}
						w={rem(100)}
						checkIconPosition='right'
						value={table.getState().pagination.pageSize.toString()}
						onChange={value => value && table.setPageSize(Number(value))}
					/>
				</>
			)}
			{!hidePagination && (
				<Pagination
					size='sm'
					total={table.getPageCount()}
					value={table.getState().pagination.pageIndex + 1}
					onChange={pageNumber => table.setPageIndex(pageNumber - 1)}
				/>
			)}
		</Group>
	);
}

import type { CSSVariablesResolver } from '@mantine/core';

export const appleCssVariables: CSSVariablesResolver = theme => ({
	variables: {
		'--app-shell-header-height': `calc(${theme.other.headerHeight} * var(--mantine-scale))`,
		'--app-shell-footer-height': `calc(${theme.other.footerHeight} * var(--mantine-scale))`,
		//'--mantine-hero-height': theme.other.heroHeight,
		'--apple-header-foreground-color': theme.other.headerForegroundColor as string,
		'--apple-header-foreground-hover-color': theme.other.headerForegroundHoverColor as string,
		'--apple-header-background-color': theme.other.headerBackgroundColor as string,
		'--apple-header-background-color-dark': theme.other.headerBackgroundColorDark as string,
		'--apple-header-backdrop-filter': theme.other.headerBackdropFilter as string,
		'--apple-backdrop-filter': theme.other.headerBackdropFilter as string,
		'--apple-dark-background-color': `light-dark(rgba(0, 0, 0, .1), rgba(255, 255, 255, .1))`,
		'--apple-dark-backdrop-filter': `saturate(180%) blur(15px)`,
		'--apple-box-shadow': `'0 11px 34px 0 var(--apple-color-box-shadow)'`,
	},
	light: {
		'--mantine-color-body': 'rgb(255, 255, 255)',
		'--apple-header-bg-color': theme.other.materials.headerViewLight.background,
		'--apple-header-bd-filter': theme.other.materials.headerViewLight.backdropFilter,
		'--apple-sidebar-bg-color': theme.other.materials.sidebarLight.background,
		'--apple-sidebar-bd-filter': theme.other.materials.sidebarLight.backdropFilter,

		'--apple-bg-primary-color': theme.other.colors.backgrounds.light.primary,
		'--apple-bg-secondary-color': theme.other.colors.backgrounds.light.secondary,
		'--apple-bg-tertiary-color': theme.other.colors.backgrounds.light.tertiary,
		'--apple-material-under-window-bg-color':
			theme.other.materials.underWindowBackgroundLight.background,
		'--apple-material-under-window-bd-filter':
			theme.other.materials.underWindowBackgroundLight.backdropFilter,

		'--apple-color-fill-primary': theme.other.colors.fills.light.primary,
		'--apple-color-fill-secondary': theme.other.colors.fills.light.secondary,
		'--apple-color-fill-tertiary': theme.other.colors.fills.light.tertiary,
		'--apple-color-fill-quaternary': theme.other.colors.fills.light.quaternary,

		'--apple-color-box-shadow': 'rgba(0, 0, 0, 0.16)',
	},
	dark: {
		'--mantine-color-body': 'rgb(20, 20, 20)',
		'--apple-header-bg-color': theme.other.materials.headerViewDark.background,
		'--apple-header-bd-filter': theme.other.materials.headerViewDark.backdropFilter,
		// '--apple-sidebar-bg-color': theme.other.colors.backgrounds.darkElevated.primary,
		'--apple-sidebar-bg-color': theme.other.materials.sidebarDark.background,
		'--apple-sidebar-bd-filter': theme.other.materials.sidebarDark.backdropFilter,

		'--apple-bg-primary-color': theme.other.colors.backgrounds.dark.primary,
		'--apple-bg-secondary-color': theme.other.colors.backgrounds.dark.secondary,
		'--apple-bg-tertiary-color': theme.other.colors.backgrounds.dark.tertiary,
		'--apple-material-under-window-bg-color':
			theme.other.materials.underWindowBackgroundDark.background,
		'--apple-material-under-window-bd-filter':
			theme.other.materials.underWindowBackgroundDark.backdropFilter,

		'--apple-color-fill-primary': theme.other.colors.fills.dark.primary,
		'--apple-color-fill-secondary': theme.other.colors.fills.dark.secondary,
		'--apple-color-fill-tertiary': theme.other.colors.fills.dark.tertiary,
		'--apple-color-fill-quaternary': theme.other.colors.fills.dark.quaternary,

		'--apple-color-box-shadow': 'rgba(0, 0, 0, 0.65)',
	},
});

import axios from 'axios';

import { announcementListSchema } from './models';
import type { AnnouncementList } from './models';

export async function getActiveAnnouncementsForUser(
	signal?: AbortSignal,
): Promise<AnnouncementList> {
	const response = await axios.get<AnnouncementList>('/api/announcements/active', { signal });

	return announcementListSchema.parse(response.data);
}

export async function acknowledgeContent(contentId: number): Promise<void> {
	await axios.post('/api/announcements', { contentId });
}

export async function getAllAnnouncementsForUser(signal?: AbortSignal): Promise<AnnouncementList> {
	const response = await axios.get<AnnouncementList>('/api/announcements/all', { signal });

	return announcementListSchema.parse(response.data);
}

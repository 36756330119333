import axios from 'axios';

import { editableMarketSchema, marketItemSchema } from './models';
import type { EditableMarket, MarketId } from './models';

export async function getMarkets(signal?: AbortSignal) {
	const data = (await axios.get<unknown>('/api/markets/all', { signal })).data;
	return marketItemSchema.array().parse(data);
}

export async function getMarket(marketId: MarketId, signal?: AbortSignal) {
	const data = (await axios.get<unknown>(`/api/management/markets/${marketId}`, { signal })).data;
	return editableMarketSchema.parse(data);
}

export async function addMarket(market: EditableMarket) {
	await axios.post('/api/management/markets', market);
}

export async function updateMarket(market: EditableMarket) {
	await axios.put(`/api/management/markets/${market.id}`, market);
}

export async function deleteMarket(marketId: MarketId) {
	await axios.delete(`/api/management/markets/${marketId}`);
}

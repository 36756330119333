import { Menu, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';

import { DarkModeIcon, LightModeIcon } from '@apple/assets/icons';

export function ToggleColorSchemeMenuItem() {
	const { toggleColorScheme } = useMantineColorScheme();
	const currentColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

	// <IconSwitchHorizontal
	// 	style={{ width: rem(16), height: rem(16) }}
	// 	stroke={1.5}
	// />
	return (
		<Menu.Item
			onClick={toggleColorScheme}
			leftSection={
				currentColorScheme === 'light' ? (
					<DarkModeIcon size={16} strokeWidth={1.5} />
				) : (
					<LightModeIcon size={16} strokeWidth={1.5} />
				)
			}
		>
			{currentColorScheme === 'light' ? 'Switch to dark theme' : 'Switch to light theme'}
		</Menu.Item>
	);
}

import { useEffect, useMemo } from 'react';
import { useDidUpdate } from '@mantine/hooks';
import { getLogger, default as logger } from 'loglevel';
import type { Logger } from 'loglevel';

export type LoggerOptions = {
	log?: Logger | string;
	name: string;
	props: unknown[];
};

export function useLogger(options: LoggerOptions) {
	const log = useMemo(
		() =>
			!options.log
				? logger
				: ((typeof options.log === 'string' ? getLogger(options.log) : options.log) ??
					logger),
		[options.log],
	);

	useEffect(() => {
		log.debug(`${options.name} mounted`, ...options.props);
		return () => log.debug(`${options.name} unmounted`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDidUpdate(() => {
		log.debug(`${options.name} updated`, ...options.props);
	}, options.props);

	return null;
}

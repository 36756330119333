import log from 'loglevel';

import { configAppInsights } from '@apple/lib/app-insights';
import { configAxios } from '@apple/lib/axios';
import { configLocalization } from '@apple/lib/i18next';
import { configLogging } from '@apple/lib/loglevel';
import { getEnv } from '@apple/utils/config/env';

export async function configApp() {
	const env = getEnv();
	const isDev = env.DEV;

	log.info(`Starting ${env.APPLE_APP_TITLE}:`, env);

	const { telemetry } = configAppInsights({
		instrumentationKey: env.APPLE_APPINSIGHTS_INSTRUMENTATION_KEY,
		roleTag: env.APPLE_APPINSIGHTS_ROLE_NAME,
	});
	const logger = configLogging(isDev ? log.levels.TRACE : log.levels.WARN, {
		auth: log.levels.TRACE,
		shop: log.levels.TRACE,
		validation: log.levels.WARN,
		DataTable: log.levels.WARN,
		form: log.levels.TRACE,
	});
	await configLocalization({
		debug: isDev,
	});
	configAxios();

	return {
		telemetry,
		log: logger,
		env,
	};
}

import { ActionIcon, parseThemeColor, rem, Tooltip, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import type { MantineColor } from '@mantine/core';

import type { IconElement } from '@apple/assets/icons';

import { toolbarCommonProps } from './toolbar-button-props';

type Props = {
	tooltip: string;
	icon: IconElement;
	disabled?: boolean;
	loading?: boolean;
	'data-testid'?: string;
	onClick?: () => void;
	color?: MantineColor;
};

export function ToolbarButton({
	tooltip,
	icon: Icon,
	onClick = () => {
		showNotification({
			title: 'Not Implemented',
			message: `'${tooltip}' has not been implemented yet`,
			withBorder: true,
		});
	},
	color,
	...rest
}: Props) {
	const theme = useMantineTheme();
	const parsedColor = !rest.disabled && color ? parseThemeColor({ color, theme }) : undefined;
	return (
		<Tooltip label={tooltip}>
			<ActionIcon {...toolbarCommonProps} {...rest} onClick={onClick}>
				<Icon style={{ width: rem(25), height: rem(25) }} color={parsedColor?.value} />
			</ActionIcon>
		</Tooltip>
	);
}

import { useMemo } from 'react';
import { useSessionStorage } from '@mantine/hooks';
import type { ColumnFilter, SortingState } from '@tanstack/table-core';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@apple/utils/pagination';

import { getColumnFiltersFromFilterData, getFilterDataFromColumnFilters } from '../utils/filters';
import type { DataTableState, FieldMap } from '../types';

type Props<TRow, TFilters> = {
	tableName: string;
	defaultFilters?: TFilters;
	defaultSorting?: SortingState;
	defaultPageSize?: number;
	fieldMap: FieldMap<TRow, TFilters>[];
};

export function useTableState<TRow, TFilters>({
	tableName,
	defaultFilters,
	defaultPageSize = DEFAULT_PAGE_SIZE,
	defaultSorting = [],
	fieldMap,
}: Props<TRow, TFilters>) {
	const defaultColumnFilters = useMemo(() => {
		return !defaultFilters
			? []
			: getColumnFiltersFromFilterData<TRow, TFilters>(defaultFilters, fieldMap);
	}, [defaultFilters, fieldMap]);

	const [state, setState] = useSessionStorage<DataTableState>({
		key: 'table-state-' + tableName,
		defaultValue: getDefaults(defaultColumnFilters, defaultSorting, defaultPageSize),
		getInitialValueInEffect: false,
	});

	const currentFilterData = useMemo(
		() => getFilterDataFromColumnFilters<TRow, TFilters>(state.columnFilters, fieldMap),
		[state.columnFilters, fieldMap],
	);

	return {
		initialState: getDefaults(defaultColumnFilters, defaultSorting, defaultPageSize),
		currentState: state,
		currentFilterData,
		setState: setState,
	};
}

function getDefaults(
	defaultColumnFilters: ColumnFilter[],
	defaultSorting: SortingState,
	pageSize: number,
): DataTableState {
	return {
		pagination: {
			pageIndex: DEFAULT_PAGE_INDEX,
			pageSize: pageSize,
		},
		globalFilter: '',
		columnFilters: defaultColumnFilters,
		sorting: defaultSorting,
	};
}

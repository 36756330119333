import { useMemo, useState } from 'react';
import { Box, Button, Modal } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

import { useTranslation } from '@apple/lib/i18next';

import { acknowledgeContent } from '../api';
import { AnnouncementItem } from './AnnouncementItem';
import type { AnnouncementList } from '../models';

type ActiveAnnouncementsProps = {
	announcementList: AnnouncementList;
};

export function ActiveAnnouncements({ announcementList }: ActiveAnnouncementsProps) {
	const { t } = useTranslation('announcement');
	const [currentIndex, setCurrentIndex] = useState(0);
	const [opened, setOpened] = useState(true);
	const [remindLaterIds, setRemindLaterIds] = useLocalStorage<number[]>({
		key: 'announcements.reminderlatercontentids',
		defaultValue: [],
		getInitialValueInEffect: false,
	});

	const announcementsToShow = useMemo(
		() =>
			announcementList.announcements.filter(
				announcement => !remindLaterIds.includes(announcement.contentId),
			),
		[announcementList.announcements, remindLaterIds],
	);

	const handleConfirm = () => {
		const announcement = announcementsToShow[currentIndex]!;
		void acknowledgeContent(announcement.contentId);

		if (currentIndex + 1 < announcementsToShow.length) {
			setCurrentIndex(prevIndex => prevIndex + 1);
		} else {
			setOpened(false);
			setCurrentIndex(0);
		}
	};

	const handleRemindLater = () => {
		const announcement = announcementsToShow[currentIndex]!;
		setRemindLaterIds(prevIds => [...prevIds, announcement.contentId]);

		if (currentIndex + 1 < announcementsToShow.length) {
			setCurrentIndex(prevIndex => prevIndex + 1);
		} else {
			setOpened(false);
			setCurrentIndex(0);
		}
	};

	const handleClose = () => {
		setOpened(false);
	};

	if (!opened || !announcementsToShow[currentIndex]) {
		return null;
	}

	return (
		<Modal
			opened={opened}
			data-testid='active-announcement-modal'
			onClose={handleClose}
			title={t('active.title')}
			closeOnClickOutside={false}
			size='xl'
			xOffset={0}
			yOffset={0}
			styles={{
				content: {
					marginLeft: 'auto',
					marginRight: 0,
				},
			}}
		>
			<AnnouncementItem announcement={announcementsToShow[currentIndex]} />
			<Box mt='md' style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button variant='outline' onClick={handleRemindLater}>
					{t('active.buttons.remindMeLater')}
				</Button>
				<Button onClick={handleConfirm} ml='sm'>
					{t('active.buttons.acknowledge')}
				</Button>
			</Box>
		</Modal>
	);
}

import { memo } from 'react';
import { Text } from '@mantine/core';
import moment from 'moment-timezone';
import type { TextProps } from '@mantine/core';

type FormattedDateProps = {
	value: moment.MomentInput;
	timezone?: string;
	humanize?: boolean;
	showTime?: boolean;
	showTimezone?: boolean;
	span?: boolean;
	humanizeLimitInDays?: number;
	size?: TextProps['size'];
};

export const FormattedDate = memo(_FormattedDate);
FormattedDate.displayName = 'FormattedDate';

function _FormattedDate({
	value,
	timezone,
	humanize = false,
	showTime = false,
	showTimezone = false,
	span = false,
	humanizeLimitInDays = 7,
	size,
}: FormattedDateProps) {
	// Ensure the value is a valid moment input
	let date = moment(value);
	if (!date.isValid()) {
		return (
			<Text size={size} span={span}>
				Invalid date
			</Text>
		);
	}

	// Apply timezone if provided
	if (timezone) {
		date = date.tz(timezone);
	}

	const currentDate = moment();
	const diffInDays = currentDate.diff(date, 'days');

	// Return humanized date if `humanize` is true and the difference in days is less than the humanize limit
	if (humanize && diffInDays < humanizeLimitInDays) {
		return (
			<Text title={date.toISOString()} size={size} span={span}>
				{date.fromNow()}
			</Text>
		);
	}

	// Determine the format based on showTime and showTimezone
	const format = showTime ? 'lll' : 'll';

	// Return the formatted date
	return (
		<Text title={date.toISOString()} size={size} span={span}>
			{date.format(format)}
			{showTimezone && ` ${date.zoneAbbr()}`}
		</Text>
	);
}

import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization';

export interface UserFilters
	extends Partial<
		Omit<UserListing, 'orderValueLimit' | 'lastSignInDate' | 'createDate' | 'isActive'>
	> {
	orderValueLimit?:
		| [number | null | undefined | string, number | null | undefined | string]
		| null;
	lastSignInDate?: [string, string] | null;
	createDate?: [string, string] | null;
	isActive?: 'active' | 'inactive';
}

/**
 * @see AppleRetail.Web.Features.Profiles.UserManagementODataController.UserData
 * @see AppleBrandedPrograms.Web.Features.Profiles.UserManagementODataController.UserData
 */
export interface UserListing {
	username: string;
	internalUserId: string;
	firstName: string;
	lastName: string;
	email: string;
	userRoles: string;
	userRolesList: string[];
	isActive: boolean;
	createDate: Date;
	activeDate: Date | string | null;
	inactiveDate: Date | string | null;
	lastSignInDate: Date;
	customerCount: number;
	numberFormatCountry: string | null;
	culture: string;
	orderValueLimit: number;
}

const userListingShape = z.object({
	username: z.string(),
	internalUserId: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
	userRoles: z.string(),
	isActive: z.boolean(),
	createDate: dateTimeSchema,
	activeDate: dateTimeSchema.nullable(),
	inactiveDate: dateTimeSchema.nullable(),
	lastSignInDate: dateTimeSchema.nullable(),
	customerCount: z.number(),
	numberFormatCountry: z.string().nullable(),
	culture: z.string(),
	userRolesList: z.array(z.string()),
	orderValueLimit: z.coerce.number(),
});

export const userListingSchema = userListingShape as z.ZodType<UserListing>;

export const userFiltersSchema = userListingShape.partial().extend({
	orderValueLimit: z
		.tuple([
			z.number().or(z.string()).optional().nullable(),
			z.number().or(z.string()).optional().nullable(),
		])
		.optional()
		.nullable(),
	lastSignInDate: z.tuple([z.string(), z.string()]).optional().nullable(),
	createDate: z.tuple([z.string(), z.string()]).optional().nullable(),
	isActive: z.enum(['active', 'inactive']).optional(),
}) satisfies z.ZodType<UserFilters>;

import { z } from 'zod';

import { programIdSchema } from '@apple/features/program/models';
import { projectIdSchema } from '@apple/features/project/models';
import { countryCodeSchema, localDateTimeSchema } from '@apple/utils/globalization';
import type { CountryCode } from '@apple/utils/globalization';

export type OrderSearchFilters = z.infer<typeof orderFiltersSchema>;
export type OrderSearchField = z.infer<typeof orderSearchFieldIdSchema>;
// export type OrderFiltersForm = Prettify<z.infer<typeof orderFiltersFormSchema>>;
export type DateRangeFilter = DateDefinedRangeFilter | DateCustomRangeFilter;
interface DateDefinedRangeFilter {
	range: '30d' | '60d' | '90d';
}

interface DateCustomRangeFilter {
	startDate: Date;
	range: 'custom';
	endDate?: Date | undefined;
}

export enum OrderCommentStatus {
	Sent = 1,
	Received = 2,
}
export interface OrderSearchListing {
	status: string;
	purchaseOrderNumber: string;
	salesOrderNumber: string;
	orderDate: string | Date;
	countryCode: CountryCode;
	appleId: string;
	customerReferenceNumber: string | null;
	displayName: string | null;
	isOrderMarkedForCancellation: boolean;
	recipient: string;
	phoneNumber: string;
	line1: string;
	line2: string | null;
	line3: string | null;
	city: string;
	postalCode: string;
	countryDisplayName: string;
	productCount: string;
	isGhostShipment: boolean;
	stateOrProvince: string | null;
	attentionTo?: string | null;
	hasComments?: boolean;
	orderCommentStatus?: OrderCommentStatus;
}

export interface AllowedOrderSearchFilters {
	filters: {
		id: OrderSearchField;
		name: string;
	}[];
}

export const orderCommentStatusSchema = z.nativeEnum(OrderCommentStatus);

/** @see AppleBrandedPrograms.Web.Features.Orders.OrderListing */
export const orderSearchListingSchema = z.object({
	countryCode: countryCodeSchema,
	appleId: z.string(),
	// Can't use orderStatusSchema here because the api returns the localized value...
	status: z.string(),
	purchaseOrderNumber: z.string(),
	salesOrderNumber: z.string(),
	// orderDate: localDateTimeSchema,
	orderDate: localDateTimeSchema,
	productCount: z.string(),
	customerReferenceNumber: z.string().nullable(),
	isOrderMarkedForCancellation: z.boolean(),
	isGhostShipment: z.boolean(),

	//TODO: Can we union the existing address schema here?
	displayName: z.string().nullable(),
	recipient: z.string(),
	attentionTo: z.string().optional().nullable(),
	line1: z.string(),
	line2: z.string().nullable(),
	line3: z.string().nullable(),
	city: z.string(),
	stateOrProvince: z.string().nullable(),
	postalCode: z.string(),
	countryDisplayName: z.string(),
	phoneNumber: z.string(),
	orderCommentStatus: orderCommentStatusSchema.optional(),
	hasComments: z.boolean().optional(),
}) satisfies z.ZodType<OrderSearchListing>;

/** @see AppleBrandedPrograms.Web.Features.Orders.OrderFilter */
export const orderFiltersSchema = z
	.object({
		orderNumber: z.string(),
		orderStatus: z.string(),
		warehouseCode: z.string(),
		countryCode: countryCodeSchema,
		customerGroupId: programIdSchema,
		orderDateRangeInDays: z.number().or(z.literal('custom')),
		startDate: z.coerce.date(),
		endDate: z.coerce.date(),
		userID: z.string(),
		appleId: z.string(),
		partNumber: z.string(),
		projectId: projectIdSchema,
		searchTerm: z.string(),
		reason: z.string(),
	})
	.partial();

const definedDateRangeFilterSchema = z.object({
	range: z.enum(['30d', '60d', '90d']),
}) satisfies z.ZodType<DateDefinedRangeFilter>;

const customDateRangeFilterSchema = z.object({
	range: z.literal('custom'),
	startDate: z.date(),
	endDate: z.date().optional(),
}) satisfies z.ZodType<DateCustomRangeFilter>;

export const dateRangeFilterSchema = z.discriminatedUnion('range', [
	definedDateRangeFilterSchema,
	customDateRangeFilterSchema,
]) as z.ZodType<DateRangeFilter>; // 	.catch({ range: '30d' });

// export const orderSearchResponseSchema = createODataPageResultSchema(orderSearchListingSchema);

// export const orderFiltersFormSchema = orderFiltersSchema.extend({
// 	dateRange: dateRangeFilterSchema.catch('60'),
// 	// startDate: z.string().optional(),
// 	// endDate: z.string().optional(),
// });

// Route's url search params
// export const orderSearchParamsSchema = orderFiltersSchema.extend({
// 	page: z.number().catch(1),
// 	limit: z.number().catch(25),
// 	startDate: z
// 		.string()
// 		.optional()
// 		.transform(x => (x === undefined ? undefined : moment(x).toDate())),
// 	endDate: z
// 		.string()
// 		.optional()
// 		.transform(x => (x === undefined ? undefined : moment(x).toDate())),
// });

const orderSearchFieldIdSchema = z.enum([
	'AppleId',
	'OrderStatus',
	'PartNumber',
	'UserId',
	'Program',
	'Country',
	'Plant',
	'Project',
	'Reason',
]);

export const allowedOrderSearchFiltersSchema = z.object({
	filters: z.array(
		z.object({
			id: orderSearchFieldIdSchema,
			name: z.string(),
		}),
	),
}) satisfies z.ZodType<AllowedOrderSearchFilters>;

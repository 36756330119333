import { useCallback, useState } from 'react';
import { Group, Pagination, Paper, Stack, Title } from '@mantine/core';

import { useTranslation } from '@apple/lib/i18next';
import type { AnnouncementList } from '@apple/features/announcements';

import { AnnouncementItem } from './AnnouncementItem';

type UserAnnouncementsProps = {
	announcementList: AnnouncementList;
};

export function UserAnnouncements({ announcementList }: UserAnnouncementsProps) {
	const { t } = useTranslation('content');
	const announcements = announcementList.announcements.sort((a, b) => {
		if (a.active !== b.active) {
			return Number(b.active) - Number(a.active);
		}
		return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
	});
	const [announcement, setAnnouncement] = useState(announcements[0]);

	const onAnnouncementChange = useCallback(
		(index: number) => setAnnouncement(announcementList.announcements[index - 1]),
		[announcementList.announcements],
	);

	return (
		<Stack>
			<Group justify='space-between'>
				<Title order={4}>{t('announcements.title')}</Title>
				<Pagination total={announcementList.totalCount} onChange={onAnnouncementChange} />
			</Group>

			{announcement && (
				<Paper withBorder p='sm'>
					<AnnouncementItem announcement={announcement} markReadOnView />
				</Paper>
			)}
		</Stack>
	);
}

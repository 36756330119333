import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

export type CartActionsProps = {
	itemsCount: number;
	onClearCart: () => void;
	onViewCart: () => void;
	onSaveCart: () => void;
};

export function CartActions({ itemsCount, onClearCart, onViewCart, onSaveCart }: CartActionsProps) {
	const { t } = useTranslation('shop');

	const handleViewCart = () => {
		onViewCart();
	};

	const handleSaveCart = () => {
		onSaveCart();
	};

	const handleClearCart = () =>
		modals.openConfirmModal({
			title: t('cart.clearModal.title'),
			children: <Text>{t('cart.clearModal.description')}</Text>,
			labels: {
				confirm: t('cart.clearModal.continue'),
				cancel: t('common:buttons.cancel'),
			},
			onConfirm: () => onClearCart(),
		});

	return (
		<>
			<Group justify='center' mt='xs'>
				{itemsCount > 0 ? (
					<Stack>
						<Box>
							<Text>{t('cart.labels.itemsCountMessage', { itemsCount })}</Text>
						</Box>
						<Button w={'14em'} onClick={handleViewCart}>
							{t('cart.buttons.viewCart')}
						</Button>
						<Group justify='center'>
							<Button
								size='compact-sm'
								onClick={handleSaveCart}
								color='green'
								variant='outline'
								rightSection={<icons.SaveCart />}
							>
								{t('cart.buttons.saveCart')}
							</Button>
							<Button
								size='compact-sm'
								rightSection={<icons.Trash />}
								variant='outline'
								color='red'
								onClick={() => {
									handleClearCart();
								}}
							>
								{t('cart.buttons.clearCart')}
							</Button>
						</Group>
					</Stack>
				) : (
					<Box mt='xs'>
						<Text>{t(`cart.labels.noItemsMessage`)}</Text>
					</Box>
				)}
			</Group>
		</>
	);
}

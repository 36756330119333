import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import type { PaginationState } from '@tanstack/react-table';

import type { QueryOptions } from '@apple/lib/tanstack-query';

import { getAllowedOrderSearchFields, getOrderSearchResults } from '../api/search';
import type { AllowedOrderSearchFilters, OrderSearchFilters } from '../models/search';

export function getOrderSearchQueryOptions(
	pagination: PaginationState,
	filters: OrderSearchFilters,
) {
	return queryOptions({
		queryKey: ['order-search', filters, pagination],
		queryFn: () => getOrderSearchResults(pagination, filters),
		placeholderData: keepPreviousData,
		throwOnError: true,
		retry: false,
	});
}

export const allowedOrderSearchFieldsQueryOptions = queryOptions({
	queryKey: ['order-search-options'],
	queryFn: () => getAllowedOrderSearchFields(),
	placeholderData: keepPreviousData,
	staleTime: 5 * 60 * 1000, // 5 minutes
}) as QueryOptions<AllowedOrderSearchFilters>;

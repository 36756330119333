import { z } from 'zod';

import { countryCodeSchema } from '@apple/utils/globalization';
import type { CountryCode } from '@apple/utils/globalization';

export type MarketId = z.infer<typeof marketIdSchema>;

/** @see AppleRetail.Web.Features.Markets.MarketItem */
export interface MarketItem {
	id: MarketId;
	name: string;
	code: string;
	plantName: string;
	countryName: string;
}

/** @see Manzanita.Data.Manzanita.CustomerGroup */
export interface EditableMarket {
	id: MarketId;
	arvatoCustomerGroupCode: string;
	name: string;
	description: string | null;
	countryCode: CountryCode;
	isDeleted: boolean;
}

export const marketIdSchema = z.number();
export const marketItemSchema = z.object({
	id: marketIdSchema,
	name: z.string(),
	code: z.string(),
	plantName: z.string(),
	countryName: z.string(),
}) as z.ZodType<MarketItem>;

export const editableMarketSchema = z.object({
	id: marketIdSchema,
	arvatoCustomerGroupCode: z.string(),
	name: z.string(),
	description: z.string().nullable(),
	countryCode: countryCodeSchema,
	isDeleted: z.boolean(),
}) as z.ZodType<EditableMarket>;

import { useCallback, useMemo } from 'react';
import { ActionIcon, Box, Button, Divider, Group, Indicator, Menu, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useNavigate } from '@tanstack/react-router';
import log from 'loglevel';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

import { CartActions } from '../components/CartActions';
import { SavedCartsList } from '../components/SavedCartList';
import { useCartIdContext } from '../contexts/cart';
import { useCartItemCount } from '../hooks/useCartItemCount';
import { useResetCart } from '../hooks/useResetCart';
import { useSavedCarts } from '../hooks/useSavedCarts';
import type { CartId } from '../models/models';

export function CartMenu() {
	const navigate = useNavigate();
	const cartId = useCartIdContext();
	const { savedCarts, activeCart, deleteCart, saveCart } = useSavedCarts();
	const resetCart = useResetCart();
	const cartItemCount = useCartItemCount(cartId);
	const { t } = useTranslation('shop');

	const cartList = useMemo(
		() => savedCarts.data?.filter(x => x.bulkOrderId !== cartId) ?? [],
		[cartId, savedCarts.data],
	);

	const handleResetCart = () =>
		modals.openConfirmModal({
			title: t('cart.switchModal.title'),
			children: <Text>{t('cart.switchModal.description')}</Text>,
			labels: {
				confirm: t('cart.switchModal.continue'),
				cancel: t('common:buttons.cancel'),
			},
			onConfirm: () =>
				resetCart.mutate(undefined, { onSuccess: () => void navigate({ to: '/cart' }) }),
		});

	const handleClearCart = useCallback(() => {
		deleteCart.mutate(cartId ?? '', { onSuccess: () => void navigate({ to: '/cart' }) });
	}, [deleteCart, navigate, cartId]);

	const handleLoadCart = useCallback(
		(cartId: CartId) => {
			activeCart.mutate(cartId, {
				onSuccess: () => void navigate({ to: '/cart/$step', params: { step: 'review' } }),
			});
		},
		[activeCart, navigate],
	);

	const handleViewCart = () => {
		return void navigate({ to: '/cart/$step', params: { step: 'review' } });
	};

	function handleSaveCart() {
		try {
			saveCart.mutate({
				bulkOrderId: cartId ?? '',
				purchaseOrderNumber: '',
				comments: '',
			});
			notifications.show({
				title: t('cart.save.success.title'),
				message: t('cart.save.success.description'),
				icon: <icons.Success />,
				autoClose: 5000,
			});
		} catch (err) {
			log.error(err);
			notifications.show({
				title: t('cart.save.fail.title'),
				message: t('cart.save.fail.description'),
				icon: <icons.Warning />,
				color: 'red',
				autoClose: 5000,
			});
		}
	}

	return (
		<Menu
			disabled={(cartItemCount.data ?? 0) <= 0 && (savedCarts.data ?? []).length <= 0}
			shadow='lg'
			width={220}
			transitionProps={{ transition: 'pop', duration: 150 }}
			data-testid='shopping-cart'
		>
			<Menu.Target>
				<Indicator
					inline
					label={cartItemCount.data ?? 0}
					disabled={(cartItemCount.data ?? 0) <= 0}
				>
					<ActionIcon
						variant='transparent'
						c={'light-dark(var(--mantine-color-black), var(--mantine-color-white))'}
						loading={savedCarts.isLoading || cartItemCount.isLoading}
					>
						<icons.ShoppingBag size='70%' />
					</ActionIcon>
				</Indicator>
			</Menu.Target>
			<Menu.Dropdown>
				<Box mt='xs'>
					<CartActions
						onClearCart={handleClearCart}
						onViewCart={handleViewCart}
						onSaveCart={handleSaveCart}
						itemsCount={cartItemCount.data ?? 0}
					/>
				</Box>

				{cartList.length > 0 && (
					<>
						<Group justify='center'>
							<Divider w={150} mt='lg' />
						</Group>
						<Box mb='md'>
							<SavedCartsList
								savedCarts={cartList}
								onClick={handleLoadCart}
								onRemoveClick={deleteCart.mutate}
							/>
						</Box>
					</>
				)}
				{(cartItemCount.data ?? 0) > 0 && (
					<Group justify='center' my='md'>
						<Button variant='transparent' onClick={handleResetCart}>
							{t('cart.labels.createNewOrder')}
						</Button>
					</Group>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}

import { useMemo } from 'react';
import {
	Box,
	Center,
	Grid,
	Group,
	Image,
	Paper,
	SimpleGrid,
	Stack,
	Text,
	Title,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { functionalUpdate } from '@tanstack/react-router';
import { getCoreRowModel, getFacetedMinMaxValues, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import type { SortingState } from '@tanstack/react-table';

import { icons, images } from '@apple/assets';
import { Calendar, ContactCenter, NewOrder, SupportIcon } from '@apple/assets/icons';
import { UserAnnouncements, useUserAnnouncements } from '@apple/features/announcements';
import { useCartItemCount } from '@apple/features/cart/hooks/useCartItemCount';
import { OrderStatus } from '@apple/features/order/components/status';
import { OrderCommentStatus } from '@apple/features/order/models/search';
import { getOrderSearchQueryOptions } from '@apple/features/order/queries/search';
import { Trans, useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import CustomIconButton from '@apple/ui/layouts/components/button-with-icon';
import { Link } from '@apple/ui/link';
import { getEnv } from '@apple/utils/config/env';
import { FormattedDate } from '@apple/utils/globalization';
import { DEFAULT_PAGE_INDEX } from '@apple/utils/pagination';
import type { CartId } from '@apple/features/cart/models/models';
import type { OrderSearchFilters, OrderSearchListing } from '@apple/features/order/models/search';

import styles from './UserHomePage.module.css';

type Props = {
	cartId: CartId;
};

export function UserHomePage({ cartId }: Props) {
	const { t } = useTranslation('user-home');
	const userAnnouncements = useUserAnnouncements();
	const theme = useMantineTheme();

	const cartItemCount = useCartItemCount(cartId);
	const env = getEnv();
	const defaultSorting: SortingState = [{ id: 'orderData', desc: true }];
	const defaultFilters = useMemo<OrderSearchFilters>(
		() => ({
			startDate: dayjs().subtract(180, 'days').startOf('day').toDate(),
		}),
		[],
	);

	const tableState = useTableState<OrderSearchListing, OrderSearchFilters>({
		tableName: 'orders-landing-page',
		defaultFilters,
		defaultSorting,
		fieldMap: [],
		defaultPageSize: 20,
	});

	const orderSearch = useQuery(
		getOrderSearchQueryOptions(
			tableState.currentState.pagination,
			tableState.currentFilterData,
		),
	);

	const table = useReactTable({
		data: orderSearch.data?.rows ?? [],
		rowCount: orderSearch.data?.totalRowCount ?? 0,
		state: tableState.currentState,
		initialState: {
			...tableState.initialState,
			pagination: { pageSize: 20, pageIndex: DEFAULT_PAGE_INDEX },
		},
		enableFilters: false,
		enableColumnFilters: false,
		enableSorting: true,
		manualFiltering: false,
		manualSorting: false,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		onPaginationChange: valueOrUpdater =>
			tableState.setState(prev => ({
				...prev,
				pagination: functionalUpdate(valueOrUpdater, prev.pagination),
			})),
		onSortingChange: valueOrUpdater =>
			tableState.setState(prev => ({
				...prev,
				sorting: functionalUpdate(valueOrUpdater, prev.sorting),
			})),
		columns: [
			{
				id: 'orderNumber',
				size: 10,
				accessorKey: 'purchaseOrderNumber',
				header: t('order:searchResults.table.columns.poNumber'),
				cell: ({ row }) => (
					<Link
						to='/orders/$poNumber'
						params={{ poNumber: row.original.purchaseOrderNumber }}
						title={t('order:searchResults.table.links.viewOrderDetails')}
					>
						<Text size='sm'>{row.original.purchaseOrderNumber}</Text>
					</Link>
				),
			},
			{
				header: t('order:searchResults.table.columns.orderDate'),
				size: 10,
				accessorKey: 'orderDate',
				cell: ({ row }) => (
					<FormattedDate value={row.original.orderDate} humanize size='sm' />
				),
			},
			{
				size: 10,
				header: t('order:searchResults.table.columns.appleId'),
				accessorKey: 'appleId',
			},
			{
				id: 'orderStatus',
				accessorKey: 'status',
				header: t('order:searchResults.table.columns.status'),
				cell: ({ row }) => (
					<OrderStatus
						status={row.original.status}
						isOrderMarkedForCancellation={row.original.isOrderMarkedForCancellation}
						size='sm'
					/>
				),
			},
			{
				id: 'orderStatusIcon',
				size: 50,
				cell: ({ row }) => {
					return (
						<>
							{row.original.hasComments ? (
								<Tooltip.Group>
									<Tooltip
										transitionProps={{ transition: 'fade-up', duration: 200 }}
										label={
											row.original.orderCommentStatus ==
											OrderCommentStatus.Sent
												? t('order-approval:tooltip.awaitingResponse')
												: t('order-approval:tooltip.responseReceived')
										}
									>
										{row.original.orderCommentStatus ==
										OrderCommentStatus.Sent ? (
											<Center>
												<icons.AdminOpenQuestion
													size={20}
													color='#147efb'
													data-testid={`has-comment-${row.original.purchaseOrderNumber}`}
												/>
											</Center>
										) : (
											<Center>
												<icons.UserRepliedQuestion
													size={20}
													color='#53d769'
													data-testid={`has-comment-${row.original.purchaseOrderNumber}`}
												/>
											</Center>
										)}
									</Tooltip>
								</Tooltip.Group>
							) : null}
						</>
					);
				},
			},
		],
	});

	const hasItems = useMemo(() => (cartItemCount.data ?? 0) > 0, [cartItemCount.data]);

	const cards = [
		{
			title: hasItems
				? t('cards.viewCart', { quantity: cartItemCount.data })
				: t('cards.shop'),
			icon: NewOrder,
			to: hasItems ? '/cart/review' : '/cart',
			dataTestId: 'create-new-order',
			color: '#61bb46',
		},
		{
			title: t('cards.help'),
			icon: SupportIcon,
			to: '/help',
			dataTestId: 'support-request',
			color: '#fdb827',
		},
		{
			title: t('cards.warehouseCalendar'),
			icon: Calendar,
			to: `${env.APPLE_CDN_URL}/filemanager/2024 Global Holiday Calendar-961054071.pdf`,
			dataTestId: 'calendar',
			target: '_blank',
			color: '#f5821f',
		},
		{
			title: t('cards.contactCenterNumbers'),
			icon: ContactCenter,
			to: `${env.APPLE_CDN_URL}/contentimages/Channel-Call-Center-June-2020.pdf`,
			dataTestId: 'contact-center',
			target: '_blank',
			color: '#e03a3e',
		},
	];

	const gridItems = cards.map((card, index) => (
		<CustomIconButton
			to={card.to}
			icon={<card.icon size='1.5rem' height='1.5rem' width='1.5rem' />}
			text={card.title}
			key={index}
			backgroundColor={card.color}
		/>
	));
	return (
		<Grid gutter={{ base: 5, xs: 'md', md: 'xl', xl: 10 }}>
			<Grid.Col span={{ base: 12, lg: 6 }}>
				<Stack>
					<SimpleGrid cols={{ base: 1, md: 2 }} py='sm' px='md' mx='xl'>
						{gridItems}
					</SimpleGrid>
					{!userAnnouncements.isLoading && !userAnnouncements.error && (
						<Box py='sm' px='md' mx='xl'>
							<UserAnnouncements announcementList={userAnnouncements.response} />
						</Box>
					)}
				</Stack>
			</Grid.Col>
			<Grid.Col span={{ base: 12, lg: 6 }}>
				<Stack>
					<Group justify='space-between'>
						<Title px='md' order={4}>
							{t('recentOrders')}
						</Title>
						<Link to='/orders/search' title='Search all orders' mx='md'>
							<icons.Search size='25' />
						</Link>
					</Group>
					<Paper mx='md' p='sm' withBorder>
						{table.getRowCount() > 0 ? (
							<DataTable
								minWidth={theme.other.page.minWidth}
								hidePageSize
								hidePagination
								table={table}
							/>
						) : (
							<Stack align='center'>
								<Title order={3}>{t('noOrders')}</Title>
								<Image radius='lg' src={images.SearchImage} w={150} fit='contain' />
								<Group>
									<Title order={3}>
										<Trans
											t={t}
											i18nKey='visitOrderHistory'
											components={{
												OrderHistoryLink: (
													<Link
														className={styles.forceInline}
														size='xl'
														to='/orders/search'
													/>
												),
											}}
										/>
									</Title>
								</Group>
							</Stack>
						)}
					</Paper>
				</Stack>
			</Grid.Col>
		</Grid>
	);
}

import { Card } from '@mantine/core';
import cx from 'clsx';

import classes from './card.module.css';

export const CardTheme = Card.extend({
	classNames: (theme, props, ctx) => ({
		root: cx(classes.card, props.variant === 'selectable' && classes.selectable),
	}),
});
